<template>
  <div class="coupon-detail">
    <div class="wrapper">
      <div class="head">
        <div class="title">
          <span v-if="state.mode === 'read'">쿠폰 정보</span>
          <span v-else>쿠폰 {{ state.mode === "create" ? "생성" : "수정" }}</span>
        </div>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-12">
            <div class="wrapper">
              <span class="title">쿠폰명</span>
              <span>{{ modalParams.couponName }}</span>
            </div>
          </div>
          <template v-if="state.mode !== 'multiple'">
            <div class="col-6">
              <div class="wrapper">
                <span class="title">쿠폰 아이디</span>
                <span>{{ modalParams.couponId }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="wrapper">
                <span class="title">쿠폰 번호 (자동 발급)</span>
                <span>{{ state.coupon.couponNo || "(N/A)" }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="wrapper">
                <span class="title">쿠폰 할당일</span>
                <span>{{ $lib.getDateFormat(state.coupon.assignDate, "yyyy-MM-dd") || "(N/A)" }}</span>
              </div>
            </div>
            <div class="col-6">
              <div class="wrapper">
                <span class="title">회원명</span>
                <span>{{ state.member.memberName || state.coupon.memberName || "(N/A)" }}</span>
              </div>
            </div>
            <div class="col-9">
              <div class="wrapper">
                <span class="title">회원 ID</span>
                <span>{{ state.member.memberId || state.coupon.memberId || "(N/A)" }}</span>
              </div>
            </div>
          </template>
          <div class="col-3" v-if="state.loaded && !state.coupon.memberId">
            <div class="wrapper">
              <button class="btn btn-bordered-secondary" @click="openMemberSearch()">
                <span>검색</span>
                <i class="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div class="col-12">
            <div class="wrapper">
              <label class="title m-0" :for="`${component.name}CouponPoint`">포인트</label>
              <Number :id="`${component.name}Code`" :value.sync="state.coupon.couponPoint" placeholder="포인트를 입력하세요" :disabled="state.mode === 'read'" className="border-focus-purple"/>
            </div>
          </div>
          <div class="col-12">
            <div class="wrapper">
              <span class="title">전환 기간</span>
              <div class="d-flex align-items-center">
                <Date :componentNameSuffix="`${component.name}S`" :id="`${component.name}AvailableStartDate`" placeholder="전환 시작일" inputClass="border-focus-purple" :value.sync="state.coupon.availableStartDate"
                      :disabled="state.mode === 'read'" format="yyyy-MM-dd"/>
                <span class="text-center p-2">-</span>
                <Date :componentNameSuffix="`${component.name}E`" :id="`${component.name}AvailableEndDate`" placeholder="전환 종료일" inputClass="border-focus-purple" :value.sync="state.coupon.availableEndDate"
                      :disabled="state.mode === 'read'" format="yyyy-MM-dd"/>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="state.mode === 'create'">
            <div class="wrapper">
              <span class="title">생성할 쿠폰 개수</span>
              <Number :id="`${component.name}Count`" :value.sync="state.count" placeholder="생성할 쿠폰 수를 입력하세요" className="border-focus-purple"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="action" v-if="state.mode !== 'read'">
      <button class="btn btn-purple btn-block" @click="save()" v-if="state.mode === 'create'">생성하기</button>
      <button class="btn btn-purple btn-block" @click="edit()" v-else-if="state.mode !== 'read'">수정하기</button>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import Date from "@/components/Date.vue";
import http from "@/scripts/http";
import Number from "@/components/Number.vue";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalCouponDetail";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Number, Date},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});

      if (modalParams.mode === "multiple") {
        state.mode = modalParams.mode;
      } else if (!modalParams.couponNos?.length) {
        state.mode = "create";
      } else {
        state.loaded = false;
        http.get(`/api/admin/coupons/pools/${modalParams.couponNos[0]}`).then(({data}) => {
          state.loaded = true;
          state.coupon = data.body;
          state.mode = state.coupon.memberId ? "read" : "edit";
        });
      }
    });

    const state = reactive({
      loaded: false,
      mode: "read",
      count: 1,
      coupon: {
        couponNo: "",
        couponPoint: 0,
        availableStartDate: "",
        availableEndDate: "",
        memberName: "",
        memberId: "",
      },
      member: {
        memberSeq: "",
        memberName: "",
        memberId: "",
      }
    });

    const openMemberSearch = () => {
      if (lib.getDateFormat(new window.Date(state.coupon.availableStartDate), "yyyyMMdd") > lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
        return store.commit("setSwingMessage", "쿠폰 전환 기간 전입니다.");
      }

      store.commit("openModal", {
        name: "MemberSearch",
        routerReplace: true,
        params: {
          couponId: modalParams.couponId,
          model: `${component.name}.state.member`,
        }
      });
    };

    const warn = (message, id) => {
      store.commit("setSwingMessage", message);
      return document.getElementById(id)?.focus();
    };

    const save = () => {
      if (!lib.isNumeric(state.coupon.couponPoint)) {
        return store.commit("setSwingMessage", "포인트는 숫자만 입력해주세요");
      } else if (state.coupon.couponPoint < 1) {
        return warn("쿠폰 포인트는 1 이상이여야 합니다");
      } else if (!state.coupon.availableStartDate) {
        return warn("쿠폰 전환 시작일을 입력해주세요");
      } else if (!state.coupon.availableEndDate) {
        return warn("쿠폰 전환 종료일을 입력해주세요");
      } else if (state.coupon.availableStartDate.replaceAll("-", "") > state.coupon.availableEndDate.replaceAll("-", "")) {
        return store.commit("setSwingMessage", "종료일은 시작일 이후로 선택해주세요");
      } else if (state.coupon.availableEndDate.replaceAll("-", "") < lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
        return store.commit("setSwingMessage", "종료일은 현재 날짜 이후로 선택해주세요");
      } else if (state.mode === "create" && !lib.isNumeric(state.coupon.couponPoint)) {
        return store.commit("setSwingMessage", "포인트는 숫자만 입력해주세요");
      } else if (state.mode === "create" && !lib.isNumeric(state.count)) {
        return store.commit("setSwingMessage", "생성 개수는 숫자만 입력해주세요");
      }

      const args = {
        couponPoint: 1,
        availableStartDate: "",
        availableEndDate: "",
      };

      for (let i in args) {
        state.coupon[i] && (args[i] = state.coupon[i]);
      }

      state.mode === "create" ? args.count = state.count : args.couponNos = modalParams.couponNos;

      if (state.mode === "create") {
        http.post(`/api/admin/coupons/${modalParams.couponId}/pools`, args).then(() => {
          store.commit("setSwingMessage", `쿠폰 ${state.count}개 생성이 완료되었습니다.`);
          close();
        });
      } else {
        http.put(`/api/admin/coupons/pools`, args).then(() => {
          store.commit("setSwingMessage", `쿠폰이 수정되었습니다.`);
          close();
        });
      }
    };

    const edit = () => {
      const args = {};
      state.coupon.couponPoint && (args.couponPoint = state.coupon.couponPoint);
      state.coupon.availableStartDate && (args.availableStartDate = state.coupon.availableStartDate);
      state.coupon.availableEndDate && (args.availableEndDate = state.coupon.availableEndDate);

      if (!Object.values(args).filter(a => !!a).length) {
        return store.commit("setSwingMessage", "수정할 값을 입력해주세요");
      } else if (args.couponPoint && !lib.isNumeric(args.couponPoint)) {
        return store.commit("setSwingMessage", "포인트는 숫자만 입력해주세요");
      } else if (args.availableStartDate && !args.availableEndDate) {
        return store.commit("setSwingMessage", "종료일을 입력해주세요");
      } else if (!args.availableStartDate && args.availableEndDate) {
        return store.commit("setSwingMessage", "시작일을 입력해주세요");
      } else if (!!args.availableStartDate && !!args.availableEndDate) {
        if (args.availableStartDate.replaceAll("-", "") > args.availableEndDate.replaceAll("-", "")) {
          return store.commit("setSwingMessage", "종료일은 시작일 이후로 선택해주세요");
        } else if (args.availableEndDate.replaceAll("-", "") < lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
          return store.commit("setSwingMessage", "종료일은 현재 날짜 이후로 선택해주세요");
        }
      }

      args.couponNos = modalParams.couponNos;

      state.member.memberSeq && (args.memberSeq = state.member.memberSeq);

      http.put(`/api/admin/coupons/pools`, args).then(() => {
        store.commit("setSwingMessage", `쿠폰이 수정되었습니다.`);
        close();
      });
    };

    const close = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const modalParams = store.getters.modalParams(component);

    return {component, state, modalParams, openMemberSearch, save, edit};
  }
});
</script>

<style lang="scss" scoped>
.coupon-detail {
  > .wrapper {
    padding: $px25;
    background: #fff;

    .head {
      margin-bottom: $px20;

      .title {
        font-size: $px18;
      }
    }

    .body {
      font-size: $px14;

      > .row {
        margin-left: $px-5;
        margin-right: $px-5;

        > div {
          padding-left: $px5;
          padding-right: $px5;

          > .wrapper {
            display: flex;
            flex-direction: column;
            justify-content: end;
            gap: $px4;
            height: 100%;

            .title {
              color: #aaa;
              font-size: $px12;
              font-weight: 500;
              flex-shrink: 0;
            }

            > .btn {
              padding: $px4 $px12;
            }
          }

          &:not(:last-child) {
            margin-bottom: $px10;
          }
        }
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;
    }
  }
}
</style>